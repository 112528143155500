export const reportType = [
  { text: 'Intermédiaire', value: 1 },
  { text: 'Final', value: 2 }
]

export const allReportType = [
  { text: 'Intermédiaire', value: 1 },
  { text: 'Final', value: 2 },
  { text: 'Plaie', value: 3 },
  { text: 'Prescription', value: 4 },
  { text: 'LIMA', value: 5 },
  { text: 'Cours et groupes', value: 6 },
  { text: 'Prestations requises', value: 7 }
]
