import { PrestationReportModel } from '@/models/prescripton-report-model'
import { Component, Vue } from 'vue-property-decorator'
import { reportType } from '../../Prescriptions/Constants'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { PrestationReportHeaders } from '../../Static'
import Commons from '@/components/shared/Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { AxiosResponse } from 'axios'
import Report from '@/views/Patients/Prescriptions/Report/Report.vue'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Confirm,
    Alert,
    Report
  }
})
export default class Reports extends Vue {
  private prescriptionService = PatientPrescriptionService.getInstance()
  private reportTypes = reportType
  private selectedItem: PrestationReportModel = {}
  public headers = PrestationReportHeaders
  public isLoading = false
  private selectedDossier!: dossierPatientModel
  public reports: PrestationReportModel[] = []
  public showConfirm = false
  public errorMessages: string[] = []
  public prestationId!: string
  public showDialog = false
  public reseauPro: any[] = []
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    this.prestationId = this.$route.params.id
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      this.selectedDossier = JSON.parse(cachedDossier)
    }
    Commons.updateDossierName()
    this.getDossierReseau()
  }

  public async getDossierReseau () {
    if (this.selectedDossier.guid) {
      Commons.getDossierReseau(this.selectedDossier.guid, this.errorMessages, this.reseauPro)
      this.getReports()
    }
  }

  private mapReports (reports: PrestationReportModel[]|void) {
    if (reports) {
      reports.forEach((report: PrestationReportModel) => {
        const medecin = this.reseauPro.find(x => x.reseauMedicalExtra && x.reseauMedicalExtra?.reseauId === report.reseauAutreId)
        if (medecin) {
          report.medecineName = medecin.fullname
        }
      })
      this.reports = reports as PrestationReportModel[]
    }
  }

  public convertTypeIdToName (typeId: number) {
    if (typeId) {
      return this.reportTypes.find(x => x.value === typeId)?.text
    }
  }

  private async getReports () {
    this.isLoading = true
    const response = await this.prescriptionService.GetPrescriptionReports(this.prestationId)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.isLoading = false
      })
    this.mapReports(response)
  }

  public async saveReport () {
    this.isLoading = true
    if (!this.selectedItem.nurseUserId) {
      this.selectedItem.nurseUserId = undefined
    }
    const res = await this.prescriptionService.AddUpdatePrescriptionReport(this.prestationId, this.selectedItem)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isLoading = false
        this.closeDialog()
      })
    if ((res as AxiosResponse<PrestationReportModel>).status === 200) {
      this.getReports()
    }
  }

  public addReport () {
    this.selectedItem = {}
    this.showDialog = true
  }

  public async generatePDFReport (report: PrestationReportModel) {
    this.$router.push({ name: 'Report', params: { id: report.id! } })
  }

  public editItem (report: PrestationReportModel) {
    this.selectedItem = report
    this.showDialog = true
  }

  public displayDeleteConfirmDialog (report: PrestationReportModel) {
    this.showConfirm = true
    this.selectedItem = report
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.isLoading = true
      const deletedItems = await this.prescriptionService.DeletePrescriptionReport(this.selectedItem.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.isLoading = false
        })

      if (deletedItems) this.getReports()
    }
  }

  public closeDialog () {
    this.selectedItem = {}
    this.showDialog = false
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public goHome () {
    this.$router.push('/patient/prescriptions')
  }
}
