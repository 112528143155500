import { PrestationReportModel } from '@/models/prescripton-report-model'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { reportType } from '../../Prescriptions/Constants'
import { AuthService } from '@/services/auth-service'
import { AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    ValidationObserver
  }
})

export default class Report extends Vue {
  private userService = AuthService.getInstance()
  @Prop()
  public report!: PrestationReportModel

  @Prop()
  public reseauPro!: any[]

  public reportTypes = reportType
  public userFullName = ''
  public users: AppUser[] = []

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
    }
    this.userFullName = this.userService.fullName
    this.getAllInfirmiereUsers()
  }

  public async getAllInfirmiereUsers () {
    this.users = await this.userService.getAllInfirmiereGroupUsers()
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.$emit('save-click')
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public close () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.$emit('close-click')
  }
}
