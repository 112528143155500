var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0),_c('c-btn',{staticClass:"btn-red",on:{"click":function($event){return _vm.addReport()}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Ajouter un nouveau rapport")])],1),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"hide-default-footer":_vm.reports.length <= 10,"loading":_vm.isLoading,"sort-by":['createdAt'],"sort-desc":[true, true],"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isLoading }},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertTypeIdToName(item.type))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-fr")(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc",on:{"click":function($event){return _vm.generatePDFReport(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-outline ")])]}}],null,true)},[_c('span',[_vm._v("Générer le rapport")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}}],null,true)}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('c-btn',{staticClass:"btn-white",on:{"click":function($event){return _vm.goHome()}}},[_c('span',[_vm._v(" Retour à la liste des prescriptions ")])])],1)],1),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":"Supprimer ce rapport","message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer ce rapport ?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}),(_vm.showDialog)?_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('report',{attrs:{"report":_vm.selectedItem,"reseauPro":_vm.reseauPro},on:{"save-click":_vm.saveReport,"close-click":_vm.closeDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }